@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package rank.client.shared.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val icon_add: DrawableResource by 
      lazy { init_icon_add() }

  public val icon_arbiter: DrawableResource by 
      lazy { init_icon_arbiter() }

  public val icon_archive: DrawableResource by 
      lazy { init_icon_archive() }

  public val icon_back: DrawableResource by 
      lazy { init_icon_back() }

  public val icon_dev: DrawableResource by 
      lazy { init_icon_dev() }

  public val icon_drop_down: DrawableResource by 
      lazy { init_icon_drop_down() }

  public val icon_empty: DrawableResource by 
      lazy { init_icon_empty() }

  public val icon_filter_all: DrawableResource by 
      lazy { init_icon_filter_all() }

  public val icon_filter_classified: DrawableResource by 
      lazy { init_icon_filter_classified() }

  public val icon_history: DrawableResource by 
      lazy { init_icon_history() }

  public val icon_info: DrawableResource by 
      lazy { init_icon_info() }

  public val icon_players: DrawableResource by 
      lazy { init_icon_players() }

  public val icon_rank: DrawableResource by 
      lazy { init_icon_rank() }

  public val icon_rules: DrawableResource by 
      lazy { init_icon_rules() }

  public val icon_settings: DrawableResource by 
      lazy { init_icon_settings() }

  public val icon_sort: DrawableResource by 
      lazy { init_icon_sort() }

  public val icon_sync: DrawableResource by 
      lazy { init_icon_sync() }

  public val icon_unarchive: DrawableResource by 
      lazy { init_icon_unarchive() }

  public val icon_warning: DrawableResource by 
      lazy { init_icon_warning() }
}

internal val Res.drawable.icon_add: DrawableResource
  get() = CommonMainDrawable0.icon_add

private fun init_icon_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-add.xml", -1, -1),
    )
)

internal val Res.drawable.icon_arbiter: DrawableResource
  get() = CommonMainDrawable0.icon_arbiter

private fun init_icon_arbiter(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_arbiter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-arbiter.xml", -1, -1),
    )
)

internal val Res.drawable.icon_archive: DrawableResource
  get() = CommonMainDrawable0.icon_archive

private fun init_icon_archive(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_archive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-archive.xml", -1, -1),
    )
)

internal val Res.drawable.icon_back: DrawableResource
  get() = CommonMainDrawable0.icon_back

private fun init_icon_back(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-back.xml", -1, -1),
    )
)

internal val Res.drawable.icon_dev: DrawableResource
  get() = CommonMainDrawable0.icon_dev

private fun init_icon_dev(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_dev",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-dev.xml", -1, -1),
    )
)

internal val Res.drawable.icon_drop_down: DrawableResource
  get() = CommonMainDrawable0.icon_drop_down

private fun init_icon_drop_down(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_drop_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-drop-down.xml", -1, -1),
    )
)

internal val Res.drawable.icon_empty: DrawableResource
  get() = CommonMainDrawable0.icon_empty

private fun init_icon_empty(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_empty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-empty.xml", -1, -1),
    )
)

internal val Res.drawable.icon_filter_all: DrawableResource
  get() = CommonMainDrawable0.icon_filter_all

private fun init_icon_filter_all(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_filter_all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-filter-all.xml", -1, -1),
    )
)

internal val Res.drawable.icon_filter_classified: DrawableResource
  get() = CommonMainDrawable0.icon_filter_classified

private fun init_icon_filter_classified(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_filter_classified",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-filter-classified.xml", -1, -1),
    )
)

internal val Res.drawable.icon_history: DrawableResource
  get() = CommonMainDrawable0.icon_history

private fun init_icon_history(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_history",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-history.xml", -1, -1),
    )
)

internal val Res.drawable.icon_info: DrawableResource
  get() = CommonMainDrawable0.icon_info

private fun init_icon_info(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_info",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-info.xml", -1, -1),
    )
)

internal val Res.drawable.icon_players: DrawableResource
  get() = CommonMainDrawable0.icon_players

private fun init_icon_players(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_players",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-players.xml", -1, -1),
    )
)

internal val Res.drawable.icon_rank: DrawableResource
  get() = CommonMainDrawable0.icon_rank

private fun init_icon_rank(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_rank",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-rank.xml", -1, -1),
    )
)

internal val Res.drawable.icon_rules: DrawableResource
  get() = CommonMainDrawable0.icon_rules

private fun init_icon_rules(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_rules",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-rules.xml", -1, -1),
    )
)

internal val Res.drawable.icon_settings: DrawableResource
  get() = CommonMainDrawable0.icon_settings

private fun init_icon_settings(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-settings.xml", -1, -1),
    )
)

internal val Res.drawable.icon_sort: DrawableResource
  get() = CommonMainDrawable0.icon_sort

private fun init_icon_sort(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_sort",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-sort.xml", -1, -1),
    )
)

internal val Res.drawable.icon_sync: DrawableResource
  get() = CommonMainDrawable0.icon_sync

private fun init_icon_sync(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_sync",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-sync.xml", -1, -1),
    )
)

internal val Res.drawable.icon_unarchive: DrawableResource
  get() = CommonMainDrawable0.icon_unarchive

private fun init_icon_unarchive(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_unarchive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-unarchive.xml", -1, -1),
    )
)

internal val Res.drawable.icon_warning: DrawableResource
  get() = CommonMainDrawable0.icon_warning

private fun init_icon_warning(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_warning",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/rank.client.shared.generated.resources/drawable/icon-warning.xml", -1, -1),
    )
)
